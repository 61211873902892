@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');


// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};
// Import the necessary classes from Bootstrap
@import 'bootstrap/scss/grid';

.input-responsive {
  @extend .col-lg-6 !optional;
  @extend .col-md-6 !optional;
  @extend .col-sm-12 !optional;
  @extend .col-xs-12 !optional;
}

// nav {
//     padding-top: 0px !important;
//     padding-left: 0px !important;
//     padding-bottom: 0px !important;
//     padding-right: 0px !important;
// }

.mat-mdc-table {
  background-color: nb-theme(background-basic-color-1);
}
.mdc-data-table__header-cell, .mdc-data-table__cell {
  color: nb-theme(text-basic-color);
  height: 15px;
}
.mat-mdc-paginator {
  background: nb-theme(background-basic-color-1);
  color: nb-theme(text-basic-color);
}

.mat-mdc-paginator-page-size-select, .mat-mdc-paginator-icon {
  color: nb-theme(text-basic-color);
}
.mat-mdc-paginator-icon{
  color: nb-theme(text-basic-color);
}

.mat-mdc-select-value-text,.mat-mdc-select-arrow {
  color: white; /* Cambia el color del cuadro de selección */
}

.mat-mdc-paginator-icon {
	fill: white !important;
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
	fill: white !important;
}


// Change the font size of the application
body {
  font-size: 60px; // Adjust the value to the desired font size
}

nb-card-body {
  padding-top: 10;
}

.no-padding {
  padding: 0 !important;
}

nb-card-header {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}


td {
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 10px !important;
}

th {

  font-size: 10px !important;
}



div.col-sm-1,
div.col-sm-2,
div.col-sm-3,
div.col-sm-4,
div.col-sm-5,
div.col-sm-6,
div.col-sm-7,
div.col-sm-8,
div.col-sm-9,
div.col-sm-10,
div.col-sm-11,
div.col-sm-12 {
  padding-left: 5px;
  padding-right: 5px;
}

.tab-link {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.responsive-div-sm {
  width: 400px;
  height: 200px;
}

.responsive-div-md {
  width: 700px;
  height: 400px;
}

.responsive-div-lg {
  width: 1000px;
  height: 400px;
}

.responsive-div-mx {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .responsive-div-md,
  .responsive-div-sm,
  .responsive-div-lg {
    width: 90%;
    height: 90%;
    overflow: auto; // Permitir desplazamiento
  }
}

nb-layout-column {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px !important;

}

::-webkit-scrollbar-track {
  background: #ffffff; /* Color de fondo de la pista de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* Color del pulgar de desplazamiento */
  border-radius: 10px; /* Radio del borde del pulgar de desplazamiento */
  border: 3px solid #ffffff; /* Borde del pulgar de desplazamiento */
}
